import { selectNormalizedCustomer } from '../../../components/BuyerProfile/Account/selectors'
import { OrderList } from '../../../utils/types/buyerCatalogs'
import { BillingAddress, CustomerResponse, DeliveryAddress, EditAccountInput, UsersTableRow } from '../../../utils/types/customer'
import { isProductInclude } from '../../../utils/types/guards/Order'
import { OrderData, SingleOrderResponse, WithSuppliers } from '../../../utils/types/Order'
import { Product, SourcingRowItem } from '../../../utils/types/Product'
import { DeliveryAddressFormValues } from '../../../components/BuyerProfile/DeliveryAddressForm/utils'
import { BillingAddressFormValues } from '../../../components/BuyerProfile/BillingAddressForm/utils'

type CatalogAction = {
  selectedCatalogName?: string
  setSelectedCatalogName?: (value: string) => void
  handleSave?: () => void
}

interface CustomerModel {
  customer?: CustomerResponse

  // My Account
  accountInfoFormValues?: EditAccountInput
  deliveryAddresses?: DeliveryAddress[]
  deliveryAddressFormValues?: DeliveryAddressFormValues
  billingAddresses?: BillingAddress[]
  billingAddressFormValues?: BillingAddressFormValues
  users?: UsersTableRow[]
  userFormValues?: { email?: string }
  setAccountInfoFormValues?: (value: EditAccountInput) => void
  handleSaveAccountInfo?: () => Promise<void>
  setDeliveryAddressFormValues?: (value: DeliveryAddressFormValues) => void
  handleSaveDeliveryAddress?: (value: DeliveryAddressFormValues) => void
  setBillingAddressFormValues?: (value: BillingAddressFormValues) => void
  handleSaveBillingAddress?: (value: BillingAddressFormValues) => void
  setUserFormValues?: (value: { email?: string }) => void
  handleSendInvite?: () => void

  // My Catalogs
  catalogs?: OrderList[]
  selectedCatalog?: OrderList
  createCatalog?: CatalogAction
  renameCatalog?: CatalogAction
  deleteCatalog?: CatalogAction
  selectedCatalogName?: string
  sourcingRowFormValues?: Partial<SourcingRowItem>
  sourcingComparisonProductUrlField?: string
  sourcingComparisonProduct?: Product
  sourcingComparisonProductName?: string
  setSelectedCatalogName?: (value: string) => void
  handleSaveCatalog?: () => void
  handleRemoveProductFromCatalog?: () => void
  handleAddToCartFromCatalog?: () => Promise<void>
  handleSourcingRowFormChange?: (sourcingRowItem: Partial<SourcingRowItem>) => void
  setSourcingComparisonProductUrlField?: (value: string) => void

  // My Orders
  orders?: OrderData[]
  selectedOrder?: WithSuppliers<SingleOrderResponse<'order'>>
}

class Customer implements CustomerModel {
  customer: CustomerModel['customer']

  accountInfoFormValues: CustomerModel['accountInfoFormValues']

  deliveryAddresses: CustomerModel['deliveryAddresses']

  setAccountInfoFormValues: CustomerModel['setAccountInfoFormValues']

  handleSaveAccountInfo: CustomerModel['handleSaveAccountInfo']

  deliveryAddressFormValues: CustomerModel['deliveryAddressFormValues']

  setDeliveryAddressFormValues: CustomerModel['setDeliveryAddressFormValues']

  handleSaveDeliveryAddress: CustomerModel['handleSaveDeliveryAddress']

  billingAddresses: CustomerModel['billingAddresses']

  billingAddressFormValues: CustomerModel['billingAddressFormValues']

  setBillingAddressFormValues: CustomerModel['setBillingAddressFormValues']

  handleSaveBillingAddress: CustomerModel['handleSaveBillingAddress']

  users: CustomerModel['users']

  userFormValues: CustomerModel['userFormValues']

  setUserFormValues: CustomerModel['setUserFormValues']

  handleSendInvite: CustomerModel['handleSendInvite']

  catalogs: CustomerModel['catalogs']

  selectedCatalog: CustomerModel['selectedCatalog']

  createCatalog: CustomerModel['createCatalog']

  renameCatalog: CustomerModel['renameCatalog']

  deleteCatalog: CustomerModel['deleteCatalog']

  selectedCatalogName: CustomerModel['selectedCatalogName']

  sourcingRowFormValues: CustomerModel['sourcingRowFormValues']

  sourcingComparisonProduct: CustomerModel['sourcingComparisonProduct']

  sourcingComparisonProductName: CustomerModel['sourcingComparisonProductName']

  sourcingComparisonProductUrlField: CustomerModel['sourcingComparisonProductUrlField']

  setSelectedCatalogName: CustomerModel['setSelectedCatalogName']

  handleSaveCatalog: CustomerModel['handleSaveCatalog']

  handleRemoveProductFromCatalog: CustomerModel['handleRemoveProductFromCatalog']

  handleAddToCartFromCatalog: CustomerModel['handleAddToCartFromCatalog']

  handleSourcingRowFormChange: CustomerModel['handleSourcingRowFormChange']

  setSourcingComparisonProductUrlField: CustomerModel['setSourcingComparisonProductUrlField']

  orders: CustomerModel['orders']

  selectedOrder: CustomerModel['selectedOrder']

  constructor(model: CustomerModel) {
    this.customer = model.customer

    this.accountInfoFormValues = model.accountInfoFormValues
    this.deliveryAddresses = model.deliveryAddresses
    this.setAccountInfoFormValues = model.setAccountInfoFormValues
    this.handleSaveAccountInfo = model.handleSaveAccountInfo
    this.deliveryAddressFormValues = model.deliveryAddressFormValues
    this.setDeliveryAddressFormValues = model.setDeliveryAddressFormValues
    this.handleSaveDeliveryAddress = model.handleSaveDeliveryAddress
    this.billingAddresses = model.billingAddresses
    this.billingAddressFormValues = model.billingAddressFormValues
    this.setBillingAddressFormValues = model.setBillingAddressFormValues
    this.handleSaveBillingAddress = model.handleSaveBillingAddress
    this.users = model.users
    this.userFormValues = model.userFormValues
    this.setUserFormValues = model.setUserFormValues
    this.handleSendInvite = model.handleSendInvite

    this.catalogs = model.catalogs
    this.selectedCatalog = model.selectedCatalog
    this.createCatalog = model.createCatalog
    this.renameCatalog = model.renameCatalog
    this.deleteCatalog = model.deleteCatalog
    this.selectedCatalogName = model.selectedCatalogName
    this.setSelectedCatalogName = model.setSelectedCatalogName
    this.handleSaveCatalog = model.handleSaveCatalog
    this.handleRemoveProductFromCatalog = model.handleRemoveProductFromCatalog
    this.handleAddToCartFromCatalog = model.handleAddToCartFromCatalog
    this.sourcingRowFormValues = model.sourcingRowFormValues
    this.handleSourcingRowFormChange = model.handleSourcingRowFormChange
    this.sourcingComparisonProductUrlField = model.sourcingComparisonProductUrlField
    this.setSourcingComparisonProductUrlField = model.setSourcingComparisonProductUrlField
    this.sourcingComparisonProduct = model.sourcingComparisonProduct
    this.sourcingComparisonProductName = model.sourcingComparisonProductName

    this.orders = model.orders
    this.selectedOrder = model.selectedOrder

    this.getCustomer = this.getCustomer.bind(this)
    this.getSelectedOrderProducts = this.getSelectedOrderProducts.bind(this)
  }

  getCustomer() {
    return selectNormalizedCustomer(this.customer || null)
  }

  getSelectedOrderProducts() {
    return this.selectedOrder?.included?.filter(isProductInclude)
  }
}

export default Customer
