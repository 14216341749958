import { PaymentIntent, PaymentMethod } from '@stripe/stripe-js'
import React, { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from 'react'

import fetcher from '../../utils/fetcher'

interface StripeContextType {
  paymentMethod: PaymentMethod | null
  setPaymentMethod: Dispatch<SetStateAction<PaymentMethod | null>>
  stripeCustomerId: string | null
  setStripeCustomerId: Dispatch<SetStateAction<string | null>>
  getPaymentMethodFromIntent: (paymentIntentId: string) => Promise<PaymentMethod | null>
  updatePaymentIntent: (orderId: string, metadata: any, country: string)
  => Promise<PaymentIntent | null>
  isLoadingPaymentMethod: boolean
  setIsLoadingPaymentMethod: Dispatch<SetStateAction<boolean>>
}

const StripeContext = createContext<StripeContextType | null>(null)

interface StripeProviderProps {
  children: ReactNode
}

export const StripeProvider: React.FC<StripeProviderProps> = ({ children }) => {
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(null)
  const [stripeCustomerId, setStripeCustomerId] = useState<string | null>(null)
  const [isLoadingPaymentMethod, setIsLoadingPaymentMethod] = useState(false)

  const getPaymentMethodFromIntent = async (orderId: string) => {
    try {
      const response = await fetcher('/api/v1/stripe/get-payment-method-from-intent', 'POST',
        JSON.stringify({ orderId }))

      const data = await response.json()

      if (data.success) {
        return data.paymentMethod as PaymentMethod
      }
      console.error('Failed to fetch payment method:', data.error)
      return null
    } catch (error) {
      console.error('Error fetching payment method:', error)
      return null
    }
  }

  const updatePaymentIntent = async (orderId: string, metadata: any, country: string) => {
    try {
      const response = await fetcher('/api/v1/stripe/update-payment-intent', 'POST',
        JSON.stringify({ orderId, metadata, country }))

      const data = await response.json()

      if (data.success) {
        return data.paymentMethod as PaymentIntent
      }
      console.error('Failed to fetch payment method:', data.error)
      return null
    } catch (error) {
      console.error('Error fetching payment method:', error)
      return null
    }
  }

  return (
    <StripeContext.Provider value={{
      paymentMethod,
      setPaymentMethod,
      stripeCustomerId,
      setStripeCustomerId,
      getPaymentMethodFromIntent,
      updatePaymentIntent,
      isLoadingPaymentMethod,
      setIsLoadingPaymentMethod,
    }}
    >
      {children}
    </StripeContext.Provider>
  )
}

export const useStripePayment = (): StripeContextType => {
  const context = useContext(StripeContext)
  if (!context) {
    throw new Error('useStripePayment must be used within a StripeProvider')
  }
  return context
}
