import TagManager from 'react-gtm-module'

import { roundToTwoDecimals } from '../math'
import { emptyEcomDataLayer, getCartTotalDiscount, getCartTotalValue, getCartEcomEventItems } from './utils'
import { CartResponse, WithSuppliers } from '../types/Order'
import { AnalyticsContext } from '../types/analytics'
import { FormValues as SampleRequestFormValues } from '../../components/Modals/ModalFlows/SampleRequest/utils'
import { Product } from '../types/Product'

interface AddShippingInfoProps {
  cartData?: WithSuppliers<CartResponse>
  context?: AnalyticsContext
  formStepValues?: SampleRequestFormValues['shippingDetailsStep']
  getLocalizedProductUrl: (product?: Product) => string
  isMatchingAddresses?: boolean
}

const addShippingInfo = ({
  cartData,
  context,
  formStepValues,
  getLocalizedProductUrl,
  isMatchingAddresses,
}: AddShippingInfoProps) => {
  const { data } = cartData || {}
  const items = cartData ? getCartEcomEventItems({ cartData, getLocalizedProductUrl }) : []

  const sampleRequestFormValues = formStepValues
    ? {
      company_name: formStepValues?.companyName,
      phone_number: formStepValues?.phoneNumber,
      delivery_address: formStepValues?.deliveryAddress,
      postal_code: formStepValues?.postalCode,
      city: formStepValues?.city,
    }
    : null

  emptyEcomDataLayer() // First empty datalayer
  TagManager.dataLayer({ dataLayer: {
    event: 'add_shipping_info',
    is_matching_addresses: isMatchingAddresses,
    ecommerce: cartData && data && {
      affiliation: 'Droppe Marketplace',
      value: roundToTwoDecimals(data.attributes['order.price']),
      tax: roundToTwoDecimals(data.attributes['order.taxvalue']),
      shipping: roundToTwoDecimals(data.attributes['order.costs']),
      currency: data.attributes['order.currencyid'],
      coupon: 'none',
      totalValue: getCartTotalValue(cartData),
      discount: getCartTotalDiscount(cartData),
      items,
    },
    context,
    ...sampleRequestFormValues,
  } })
}

export default addShippingInfo
