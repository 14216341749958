import { ItemDataType } from 'rsuite/esm/@types/common'

import { Catalog } from '../../../utils/types/Product'

type CategoryPickerLightItem = CategoryItem | TriggerItem

interface CategoryItem {
  value: string
  label: string
  id: string
  children: CategoryPickerLightItem[]
  data: Catalog
}

interface TriggerItem {
  value: string
  label: string
  triggerSuggestion: boolean
  parentCategory: string
  level: number
  parent: CategoryItem
}

interface CategoryPickerModel {
  catalogs: Catalog[]
  categories?: CategoryPickerLightItem[]
  handleChange?: (value: string) => void
  handleSelect?: (selectedItem: ItemDataType, selectedPath: ItemDataType[]) => void
}

class CategoryPicker {
  catalogs: CategoryPickerModel['catalogs']

  categories: CategoryPickerModel['categories']

  handleChange: CategoryPickerModel['handleChange']

  handleSelect: CategoryPickerModel['handleSelect']

  constructor(model: CategoryPickerModel) {
    this.catalogs = model.catalogs
    this.categories = model.categories
    this.handleChange = model.handleChange
    this.handleSelect = model.handleSelect

    this.getPathByCode = this.getPathByCode.bind(this)
  }

  getPathByCode(code: string): Catalog[] {
    const findCategory = (thisCode: string) => this.catalogs.find((cat) => cat['catalog.code'] === thisCode)

    const buildPath = (category: Catalog | undefined, path: Catalog[] = []): Catalog[] => {
      if (!category) {
        return path
      }
      const newPath = [category, ...path]

      if (category['catalog.level'] === 1) {
        return newPath
      }

      const parentCategory = this.catalogs.find((cat) => cat.id === category['catalog.parentid'])

      return buildPath(parentCategory, newPath)
    }

    const chosenCategory = findCategory(code)
    return buildPath(chosenCategory)
  }
}

export default CategoryPicker
