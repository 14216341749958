import RequestForQuote from './RequestForQuote'
import RequestForSample from './RequestForSample'
import SingleProduct from './SingleProduct'
import Customer from './Customer'
import Auth from './Auth'
import RequestForDocs from './RequestForDocs'
import ResetPassword from './ResetPassword'
import Router from './Router'
import ProductQuantity from './ProductQuantity'
import Cart from './Cart'
import CategoryPicker from './CategoryPicker'
import Toaster from './Toaster'
import Supplier from './Supplier'
import PropertyPicker from './PropertyPicker'

export type AppModels = {
  Router: Router
  Auth: Auth
  Customer: Customer
  Supplier: Supplier
  Cart: Cart
  SingleProduct: SingleProduct
  ProductQuantity: ProductQuantity
  RequestForQuote: RequestForQuote
  RequestForSample: RequestForSample
  RequestForDocs: RequestForDocs
  ResetPassword: ResetPassword
  CategoryPicker: CategoryPicker
  PropertyPicker: PropertyPicker
  Toaster: Toaster
}

export default {
  Router,
  Auth,
  Customer,
  Supplier,
  Cart,
  SingleProduct,
  ProductQuantity,
  RequestForQuote,
  RequestForSample,
  RequestForDocs,
  ResetPassword,
  CategoryPicker,
  PropertyPicker,
  Toaster,
}
